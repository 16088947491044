import Header from '../Header.vue'
import Footer from '../Footer.vue'
import CheckInService from '../../api/CheckInService.js'

export default{
	name:'LegacyIndex',
	data(){
		return{
			searchType:'',
			bannerList:[],
			LegacyModelList:[],//彩塑
			LegacyRelicList:[],//出土文物
			LegacyGrottoList:[],//石窟
			LegacyImgList:[],//壁画
			LegacyAdditionalList:[],//其他
			LegacySitesList:[],//遗址
			LegacyLiteratureList:[],//文献
			LegacyFigureList:[],//人物
			LegacyArtList:[],//艺术品
			LegacyPublishList:[],//出版物
			SearchWordsList:[],
			LegacyList:[
				{value:this.$LegacyModelId,label:'彩塑'},
				{value:this.$LegacyRelicId,label:'出土文物'},
				{value:this.$LegacyGrottoId,label:'石窟'},
				{value:this.$LegacyImgId,label:'壁画'},
				{value:this.$LegacySitesId,label:'遗址'},
				{value:this.$LegacyLiteratureId,label:'文献'},
				{value:this.$LegacyFigureId,label:'人物'},
				{value:this.$LegacyArtId,label:'艺术品'},
				{value:this.$LegacyPublishId,label:'出版物'},
				{value:this.$LegacyAdditionalId,label:'其他'}
				],
			keyWord:'',
			defaultImg: 'this.src="' + require('../../images/second_banner.png') + '"', //默认banner
		}
	},
	methods:{
		GetBanner(){ //获取banner
			var that = this
			CheckInService.GetBanner(4).then(res=>{
				if(res.Tag == 1){
					that.bannerList = res.Result
				}
			})
		},
		GetLegacyModelList(){ //获取彩塑
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyModelId,10,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyModelList = res.Result
				}
			})
		},
		GetLegacyRelicList(){ //出土文物
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyRelicId,6,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyRelicList = res.Result
				}
			})
		},
		GetLegacyGrottoList(){ //石窟
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyGrottoId,6,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyGrottoList = res.Result
				}
			})
		},
		GetLegacyImgList(){ //壁画
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyImgId,10,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyImgList = res.Result
				}
			})
		},
		GetLegacyAdditionalList(){ //其他
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyAdditionalId,6,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyAdditionalList = res.Result
				}
			})
		},
		GetLegacySitesList(){ //遗址
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacySitesId,6,3).then(res=>{
				if(res.Tag == 1){
					that.LegacySitesList = res.Result
				}
			})
		},
		GetLegacyLiteratureList(){ //文献
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyLiteratureId,10,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyLiteratureList = res.Result
				}
			})
		},
		GetLegacyFigureList(){ //人物
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyFigureId,6,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyFigureList = res.Result
				}
			})
		},
		GetLegacyArtList(){ //艺术品
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyArtId,6,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyArtList = res.Result
				}
			})
		},
		GetLegacyPublishList(){ //出版物
			var that = this
			CheckInService.GetIndexClassResList(that.$LegacyPublishId,10,3).then(res=>{
				if(res.Tag == 1){
					that.LegacyPublishList = res.Result
				}
			})
		},
		GetSearchWords(){ //获取搜索词
			var that = this
			CheckInService.GetSearchWords(5).then(res=>{
				if(res.Tag == 1){
					that.SearchWordsList = res.Result
				}
			})
		},
		toMoreList(typeName,classId,type){ //更多
			window.open(this.$WebUrl+'ImageList?classId='+classId + '&type='+ type + '&typeName='+ typeName)
		},
		toImageDetail(id){ //图片详情
			window.open(this.$WebUrl+'ImageDetail?id='+id)
		},
		toList(){ //搜索
			var typeName=''
			this.LegacyList.find(item =>{
				if(item.value == this.searchType){
					typeName = item.label
				}
			})
			window.open(this.$WebUrl+'ImageList?classId='+this.searchType + '&type='+ 3 + '&typeName='+ typeName + '&keyWord='+this.keyWord)
		},
		chooseSearch(word){
			this.keyWord = word
			this.toList()
		},
		toSeniorSearch(){ //高级检索
			this.$router.push({
				path:'/SeniorSearch'
			})
		}
	},
	created(){
		this.GetBanner()
		this.GetSearchWords()
		this.GetLegacyModelList()
		this.GetLegacyRelicList()
		this.GetLegacyGrottoList()
		this.GetLegacyImgList()
		this.GetLegacyAdditionalList()
		this.GetLegacySitesList()
		this.GetLegacyLiteratureList()
		this.GetLegacyFigureList()
		this.GetLegacyArtList()
		this.GetLegacyPublishList()
		this.searchType = this.LegacyList[0].value
	},
	components:{
		'Header': Header,
		'Footer':Footer
	}
}